import {
  Box,
  Button,
  InputGroup,
  InputRightElement,
  Text,
  useToast
} from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import { FormikProvider, useFormik } from 'formik'
import React, { useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { setSelectedTabDiscounts } from '../../../actions'
import { ArrowIcon } from '../../../assets/Icons/ArrowIcon'
import { PlusIcon } from '../../../assets/Icons/PlusIcon'
import { friendlyTimeWithTZ } from '../../../libs'
import { usePartners } from '../../../libs/hooks/usePartners'
import {
  usePromocode,
  useUpdatePromocode
} from '../../../libs/hooks/usePromo'
import DatePickerModal from '@/components/Booking/Modals/DatePickerModal'
import ButtonsToggle from '@/components/Common/ButtonsToggle'
import CustomDaySelector from '@/components/Common/CustomDaySelector'
import ExperienceApplyList from '@/components/Common/ExperienceApplyList'
import FormikDropdown from '@/components/Common/FormikComponents/FormikDropdown'
import FormikTwoStyledInput from '@/components/Common/FormikComponents/FormikTwoStyledInput'
import { editPromoValidation } from '../../../configs/validation/editPromocode'
import {
  DISCOUNTS_URL,
  matchMedia768InState,
  matchMedia1440InState,
  PROMO_PAGE_URL
} from '../../../constants'

export default function EditPromocode() {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const toast = useToast()
  const customColors = theme.colors.custom
  const isLargerThan1440 = useSelector(matchMedia1440InState)
  const isLargerThan768 = useSelector(matchMedia768InState)
  const queryClient = useQueryClient()
  const { id: promoId = 'undefined' } = useParams()
  const { data: { results: partners = [] } = {} } = usePartners(
    {},
    { refetchOnWindowFocus: false }
  )
  const minDate = new Date(2021, 0, 1, 0, 0, 0)
  const optionsPartners = useMemo(() => {
    if (partners.length !== 0) {
      return partners.map((part) => {
        return {
          value: part.id,
          label: part.name
        }
      })
    } else {
      return []
    }
  }, [partners])
  const { data: promocode = {}, isLoading } = usePromocode(promoId, {
    refetchOnWindowFocus: false
  })
  const { mutate: updatePromo } = useUpdatePromocode()
  const code = promocode?.code ? promocode?.code : ''
  const available = promocode?.available ? promocode?.available : ''
  const reason = promocode?.reason ? promocode?.reason : ''
  const discountCent = promocode?.discount_cent
    ? `${promocode?.discount_cent / 100}`
    : ''
  const discountPercent = promocode?.discount_percent
    ? `${promocode?.discount_percent}`
    : ''
  const discountToShow =
    discountPercent || discountCent ? discountPercent || discountCent : ''
  const minPlayers = promocode?.players_number
    ? promocode?.players_number?.lower
      ? promocode?.players_number?.lower
      : ''
    : ''
  const maxPlayers = promocode?.players_number
    ? promocode?.players_number?.upper
      ? promocode?.players_number?.upper
      : ''
    : ''
  const availableAllTime =
    new Date(promocode?.booking_interval_end)?.getUTCFullYear() > 3000 &&
    new Date(promocode?.order_interval_end)?.getUTCFullYear() > 3000
  const bookingIntervalStart = Date.parse(promocode?.booking_interval_start)
  const bookingIntervalEnd = Date.parse(promocode?.booking_interval_end)
  const orderIntervalStart = Date.parse(promocode?.order_interval_start)
  const orderIntervalEnd = Date.parse(promocode?.order_interval_end)
  const bookingWeekdays = promocode?.booking_weekdays
    ? promocode?.booking_weekdays
    : []
  const orderWeekdays = promocode?.order_weekdays
    ? promocode?.order_weekdays
    : []
  const eventvariants = promocode?.eventvariants ? promocode?.eventvariants : []
  function getInitialPartner() {
    let checkedPartner = ''
    if (promocode?.partner && partners.length !== 0) {
      for (let i = 0; i < partners.length; i++) {
        if (promocode.partner === partners[i].id) {
          checkedPartner = {
            value: partners[i].id,
            label: partners[i].name
          }
        }
      }
    }
    return checkedPartner
  }

  function getDiscountType(discountPercent, discountCent) {
    if (discountPercent) {
      return '%'
    }
    if (discountCent) {
      return '$'
    }
  }

  function goTo(path) {
    return () => history.push(path)
  }

  function updateArrayValue(setFieldValue, prop) {
    return (array) => setFieldValue(prop, array)
  }

  function addPartnerHandler() {
    goTo(DISCOUNTS_URL)()
    dispatch(setSelectedTabDiscounts(2))
  }

  function onSubmit(values) {
    const toSend = {
      promoId: promoId,
      available: values.available,
      code: values.code,
      ...(values.discount &&
        values.typeDiscount === '$' && {
          discountCent: values.discount * 100,
          discountPercent: null
        }),
      ...(values.discount &&
        values.typeDiscount === '%' && {
          discountPercent: values.discount,
          discountCent: null
        }),
      bookingIntervalStart: values.availableAllTime.value
        ? null
        : new Date(values.bookFrom).toISOString(),
      bookingIntervalEnd: values.availableAllTime.value
        ? null
        : new Date(values.bookTo).toISOString(),
      orderIntervalStart: values.availableAllTime.value
        ? null
        : new Date(values.playFrom).toISOString(),
      orderIntervalEnd: values.availableAllTime.value
        ? null
        : new Date(values.playTo).toISOString(),
      bookingWeekdays: values.bookingWeekdays,
      orderWeekdays: values.orderWeekdays,
      minPlayers: values.playersMin,
      maxPlayers: values.playersMax,
      eventvariants: values.eventvariants,
      partner: values?.partner?.value ? values?.partner?.value : '',
      reason: values.reason
    }
    updatePromo(
      { obj: toSend },
      {
        onSuccess: () => {
          queryClient
            .invalidateQueries(['promo', promoId])
            .then(() => goTo(`${PROMO_PAGE_URL}/${promoId}`)())
        }
      }
    )
  }

  const optionsAvailableAllTime = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ]

  const formik = useFormik({
    initialValues: {
      code: code,
      available: available,
      reason: reason,
      discount: discountToShow,
      typeDiscount: getDiscountType(discountPercent, discountCent),
      playersMin: minPlayers,
      playersMax: maxPlayers,
      bookFrom: availableAllTime ? new Date() : bookingIntervalStart,
      bookFromText: '',
      bookTo: availableAllTime ? new Date() : bookingIntervalEnd,
      bookToText: '',
      playFrom: availableAllTime ? new Date() : orderIntervalStart,
      playFromText: '',
      playTo: availableAllTime ? new Date() : orderIntervalEnd,
      playToText: '',
      bookingWeekdays: bookingWeekdays,
      orderWeekdays: orderWeekdays,
      eventvariants: eventvariants,
      partner: getInitialPartner(),
      availableAllTime: availableAllTime
        ? optionsAvailableAllTime[0]
        : optionsAvailableAllTime[1]
    },
    enableReinitialize: JSON.stringify(promocode) === '{}',
    validationSchema: editPromoValidation,
    onSubmit: onSubmit
  })
  const formikValues = formik.values
  const handleSubmit = formik.handleSubmit
  const errors = formik.errors
  const touched = formik.touched
  const setFieldValue = formik.setFieldValue
  const isValidating = formik.isValidating
  const isSubmitting = formik.isSubmitting

  function setDateHandler(prop, textProp) {
    return (value) => {
      setFieldValue(prop, value)
      setFieldValue(textProp, friendlyTimeWithTZ(value, 'MM.DD.YY'))
    }
  }

  return (
    <Box
      p={{ base: '24px 16px', md: '32px 24px', '2xl': '48px 0 0' }}
      maxW={{ '2xl': '1100px' }}
      m={{ '2xl': '0 auto' }}
    >
      <Button
        variant="ghost"
        fontFamily="inherit"
        p="0"
        w={{ md: '100%', '2xl': 'auto' }}
        justifyContent="start"
        mb="24px"
        onClick={goTo(`${PROMO_PAGE_URL}/${promoId}`)}
      >
        <Text
          as={'span'}
          fontSize={{ base: '24px', '2xl': '32px' }}
          fontWeight={{ base: '600' }}
          color="custom.black.500"
          lineHeight="164%"
          d="flex"
          flexWrap="wrap"
          alignItems="center"
        >
          <ArrowIcon
            w="auto"
            height="16px"
            mr="16px"
            mb="2px"
            color="custom.gray.200"
          />
          Edit&nbsp;
          <Text as="p" d={{ base: 'inline-box', md: 'none' }}>
            promo
          </Text>
          <Text as="p" d={{ base: 'none', md: 'inline-block' }}>
            promocode
          </Text>
          <Text
            as="p"
            ml="6px"
            maxW={{ base: '275px', md: 'unset' }}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {code}
          </Text>
        </Text>
      </Button>
      {!isLoading && (
        <FormikProvider value={formik}>
          <>
            <Box
              bg="white"
              p={{ base: '16px' }}
              d="grid"
              gridTemplateColumns={{ base: '1fr 1fr', md: 'repeat(3, 1fr)' }}
              gridGap={{ base: '8px', md: '16px' }}
              fontSize={{ base: '12px', '2xl': '14px' }}
            >
              <FormikTwoStyledInput
                name="code"
                id="code"
                placeholder="Code"
                gridColumn={{ base: '1/3', md: '1/2' }}
              />
              <FormikTwoStyledInput
                name="available"
                id="available"
                placeholder="Available"
                gridColumn={{ base: '1/3', md: '2/3' }}
              />
              <Box d="flex" gridColumn={{ base: '1/3', md: '3/4' }}>
                <FormikDropdown
                  main={{ options: optionsPartners }}
                  formik={{
                    name: 'partner',
                    id: 'partner'
                  }}
                  chakraProps={{ w: '100%' }}
                  label={isLargerThan1440 ? 'Partner' : ''}
                  selectProps={{
                    styles: {
                      control: {
                        height: isLargerThan1440 ? '56px' : '40px',
                        padding: isLargerThan1440 ? '12px 4px 0' : '0',
                        border: !isLargerThan768 && 'none',
                        background:
                          errors.partner && touched.partner
                            ? `${customColors.red[20]}`
                            : `${customColors.lightGray}`
                      }
                    }
                  }}
                />
                <Button
                  variant="likeInput"
                  ml="8px"
                  w="40px"
                  h="100%"
                  onClick={addPartnerHandler}
                >
                  <PlusIcon color="custom.blue.600" />
                </Button>
              </Box>
              <Box
                gridColumn={{ base: '1/3', md: '1/4' }}
                d="grid"
                gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
                gridGap={{ base: '8px', md: '16px' }}
              >
                <FormikTwoStyledInput
                  name="reason"
                  id="reason"
                  placeholder="Reason"
                />
                <Box d="flex">
                  <FormikTwoStyledInput
                    name="discount"
                    id="discount"
                    placeholder="Discount"
                  />
                  <ButtonsToggle
                    data={['$', '%']}
                    initialState={formikValues.typeDiscount}
                    ml="8px"
                    w={{ base: '132px' }}
                    h="100%"
                    cb={(val) => setFieldValue('typeDiscount', val)}
                    unCheckedColor="custom.lightGray"
                  />
                </Box>
              </Box>
              <Box
                gridColumn={{ base: '1/3', md: '1/4' }}
                d="grid"
                gridTemplateColumns={{ base: '1fr', md: '1fr 1fr 1fr' }}
                gridGap={{ base: '8px', md: '16px' }}
              >
                <FormikTwoStyledInput
                  name="playersMin"
                  id="playersMin"
                  placeholder="Players Min"
                />
                <FormikTwoStyledInput
                  name="playersMax"
                  id="playersMax"
                  placeholder="Players Max"
                />
                <FormikDropdown
                  main={{ options: optionsAvailableAllTime }}
                  formik={{
                    name: 'availableAllTime',
                    id: 'availableAllTime'
                  }}
                  chakraProps={{ w: '100%' }}
                  label={isLargerThan1440 ? 'Available all time' : ''}
                  selectProps={{
                    styles: {
                      control: {
                        height: isLargerThan1440 ? '56px' : '40px',
                        padding: isLargerThan1440 ? '12px 4px 0' : '0',
                        border: !isLargerThan768 && 'none'
                      }
                    }
                  }}
                />
              </Box>
              <InputGroup gridColumn={{ base: '1/2' }}>
                <FormikTwoStyledInput
                  id="bookFromText"
                  name="bookFromText"
                  placeholder="Book from"
                  opacity={formikValues.availableAllTime.value ? 0.6 : 1}
                  isDisabled={true}
                />
                <InputRightElement h="100%">
                  <DatePickerModal
                    minDate={minDate}
                    returnMilliseconds={true}
                    isDisabled={formikValues.availableAllTime.value}
                    selectedDate={formikValues.bookFrom}
                    cb={setDateHandler('bookFrom', 'bookFromText')}
                  />
                </InputRightElement>
              </InputGroup>
              <InputGroup gridColumn={{ base: '2/3' }}>
                <FormikTwoStyledInput
                  id="bookToText"
                  name="bookToText"
                  placeholder="Book to"
                  opacity={formikValues.availableAllTime.value ? 0.6 : 1}
                  isDisabled={true}
                />
                <InputRightElement h="100%">
                  <DatePickerModal
                    returnMilliseconds={true}
                    minDate={minDate}
                    selectedDate={formikValues.bookTo}
                    isDisabled={formikValues.availableAllTime.value}
                    cb={setDateHandler('bookTo', 'bookToText')}
                  />
                </InputRightElement>
              </InputGroup>
              <CustomDaySelector
                gridColumn={{ base: '1/3', md: '3/4' }}
                needIdx={true}
                isDisabled={formikValues.availableAllTime.value}
                initialState={formikValues.bookingWeekdays}
                cb={updateArrayValue(setFieldValue, 'bookingWeekdays')}
              />
              <InputGroup gridColumn={{ base: '1/2' }}>
                <FormikTwoStyledInput
                  id="playFromText"
                  name="playFromText"
                  opacity={formikValues.availableAllTime.value ? 0.6 : 1}
                  placeholder="Play From"
                  isDisabled={true}
                />
                <InputRightElement h="100%">
                  <DatePickerModal
                    minDate={minDate}
                    returnMilliseconds={true}
                    selectedDate={formikValues.playFrom}
                    isDisabled={formikValues.availableAllTime.value}
                    cb={setDateHandler('playFrom', 'playFromText')}
                  />
                </InputRightElement>
              </InputGroup>
              <InputGroup gridColumn={{ base: '2/3' }}>
                <FormikTwoStyledInput
                  id="playToText"
                  name="playToText"
                  opacity={formikValues.availableAllTime.value ? 0.6 : 1}
                  placeholder="Play to"
                  isDisabled={true}
                />
                <InputRightElement h="100%">
                  <DatePickerModal
                    returnMilliseconds={true}
                    isDisabled={formikValues.availableAllTime.value}
                    selectedDate={formikValues.playTo}
                    minDate={minDate}
                    cb={setDateHandler('playTo', 'playToText')}
                  />
                </InputRightElement>
              </InputGroup>
              <CustomDaySelector
                gridColumn={{ base: '1/3', md: '3/4' }}
                isDisabled={formikValues.availableAllTime.value}
                needIdx={true}
                initialState={formikValues.orderWeekdays}
                cb={updateArrayValue(setFieldValue, 'orderWeekdays')}
              />
            </Box>
            <Box p={{ base: '16px' }} bg="white">
              <ExperienceApplyList
                label="Experiences"
                error={touched.eventvariants && errors.eventvariants}
                initialState={formikValues.eventvariants}
                cb={updateArrayValue(setFieldValue, 'eventvariants')}
                w="100%"
                mb="16px"
                fontSize={{ base: '12px', '2xl': '14px' }}
              />
              <Box
                d="grid"
                gridTemplateColumns="1fr 1fr"
                gridGap="8px"
                fontSize={{ base: '12px', '2xl': '14px' }}
              >
                <Button
                  variant="lightBlue"
                  onClick={goTo(`${PROMO_PAGE_URL}/${promoId}`)}
                >
                  Cancel
                </Button>
                <Button variant="blue" onClick={handleSubmit}>
                  Save
                </Button>
              </Box>
            </Box>
          </>
        </FormikProvider>
      )}
    </Box>
  )
}
